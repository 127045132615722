import * as fb from "../../firebase";
export default {
    state: {
        avatarLoaded: false,
        count: 0,
        engineID: null,
        langID: null,
        voiceID: null,
        avatarSpeech: '',
        selectedAvatar: [],
        selectAvatarViewer: false,
        avatarConfigViewer: {},
        updateAvatarConfirm: {}
    },
    getters: {
        getAvatarLoaded: state => state.avatarLoaded,
        getEngineID: state => state.engineID,
        getLangID: state => state.langID,
        getVoiceID: state => state.voiceID,
        getAvatarSpeech: state => state.avatarSpeech,
        selectedAvatar: state => state.selectedAvatar,
        avatarConfigViewer: state => state.avatarConfigViewer,
        updateAvatarConfirm: state => state.updateAvatarConfirm,
        selectAvatarViewer: state => state.selectAvatarViewer
    },
    mutations: {
        setAvatarLoaded(state, status) {
            state.avatarLoaded = status;
        },
        setEngineID(state, code) {
            state.engineID = code
        },
        setLangID(state, code) {
            state.langID = code
        },
        setVoiceID(state, code) {
            state.voiceID = code
        },
        setAvatarSpeech(state, speech) {
            console.log('Speech: ', speech)
            state.avatarSpeech = speech;
        },
        setSelectedAvatar(state, avatarObj) {
            state.selectedAvatar.push(avatarObj)
        },
        setSelectAvatarViewer(state, status) {
            state.selectAvatarViewer = status
        },
        clearAvatarSelection(state) {
            state.selectedAvatar = []
        },
        removeAvatar(state, id) {
            state.selectedAvatar.splice(id, 1)
        },
        openAvatarConfigViewer(state, configObj) {
            state.avatarConfigViewer = configObj
        },
        closeAvatarConfigViewer(state) {
            state.avatarConfigViewer = {
                show: false
            }
        },
        setUpdateAvatarConfirm(state, avatarObj) {
            state.updateAvatarConfirm = avatarObj
        }
    },
    actions: {
        loadAvatar({ getters, state }, avatarDetails) {
            let sc;
            console.log('Avatar Details: ', avatarDetails)
            console.log('Avatar Loading Started')
            console.log('if already loaded: ', document.getElementsByTagName('script'))
            sc = document.createElement('script');
            sc.type = "text/javascript";
            sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=" + avatarDetails.sitepalID + "&js=0";
            sc.onload = () => {
                console.log('Coming here on load')
                if (typeof AC_VHost_Embed == 'function') {
                    var script = document.createElement('script');
                    script.id = "avatarScript"
                    script.type = "text/javascript";
                    script.innerHTML =
                        "AC_VHost_Embed(7295865," + getters.avatarConfigDimension.avatarWidth + "," + getters.avatarConfigDimension.avatarWidth + ",'',1,0," + avatarDetails.avatarID + ",0,1,1,'RwZIxMafWux6BWjhauvuPFZP3UcJLcqh',0, '400|300|15|15|L|T|false|0|0|0|0|0|C|0|0|0|992')";
                    document.getElementById('divVHSS').appendChild(script);
                }
            }
            console.log('Source: ', sc)
            document.head.appendChild(sc);
        },
        updateAvatarConfig({ commit }, avatarObj) {
            console.log('Avatar Object on update avatar: ', avatarObj)
            fb.kioskCollection.doc(avatarObj.kioskId).update({
                avatarList: avatarObj
            }).then(() => {
                commit('closeAvatarConfigViewer')
            })
        }
    }
}