<template>
    <v-dialog v-model="selectAvatarViewer" max-width="65%" persistent>
        <v-card flat width="100%" :height="selectAvatarViewerDimension.outerCardHeight">
            <v-card-title class="justify-center"> Update Avatar </v-card-title>
            <v-divider></v-divider>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-card flat :height="selectAvatarViewerDimension.innerCardHeight" class="overflow-y-auto">
                        <v-row no-gutters>
                            <v-col cols="2" v-for="(avatar, i) in avatars" :key="i">
                                <v-card class="ma-4" @click="selectAvatar(avatar)">
                                    <v-img :src=getImage(avatar.avatarImage.url)></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card flat :height="selectAvatarViewerDimension.innerCardHeight" class="overflow-y-auto">
                        <v-text-field v-model="avatarName" label="Enter Avatar Name" outlined dense></v-text-field>
                        <avatar></avatar>
                    </v-card>
                </v-col>
                <v-col cols="12" align="center" class="mt-2">
                    <v-btn @click="saveSelectedAvatar()" class="ma-4" color="primary" width="175"
                        :disabled="updateDisable">Add Avatar</v-btn>
                    <v-btn @click="$store.commit('setSelectAvatarViewer', false)" class="ma-4" color="error"
                        width="175">Close</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import Avatar from '../../avatar.vue';
export default {
    name: "avatarConfig",
    data() {
        return {
            show: false,
            avatarName: '',
            selectedAvatar: null,
            updateDisable: true
        }
    },
    components: {
        Avatar
    },
    computed: {
        ...mapGetters(['avatarList', 'selectAvatarViewer', 'selectAvatarViewerDimension', 'selectedKiosk']),
        avatars() {
            return this.avatarList
        }
    },
    methods: {
        getImage(avatar) {
            return 'https://strapi.arsconnect.com' + avatar
        },
        selectAvatar(avatar) {
            console.log('Selected Avatar: ', avatar, this.avatarConfigViewer)
            this.selectedAvatar = avatar
            this.updateDisable = false
            this.$store.dispatch('loadAvatar', {
                sitepalID: avatar.sitepalId,
                avatarID: avatar.avatarId
            })

        },
        saveSelectedAvatar() {
            let langInfo;
            if (this.selectedAvatar === null) {
                this.showBanner = true
            } else {
                if (this.selectedAvatar.gender === 'female') {
                    langInfo = {
                        en: {
                            langId: 1,
                            engineId: 3,
                            voiceId: 3
                        },
                        es: {
                            langId: 2,
                            engineId: 4,
                            voiceId: 4
                        }
                    }
                } else {
                    langInfo = {
                        en: {
                            langId: 1,
                            engineId: 3,
                            voiceId: 7
                        },
                        es: {
                            langId: 2,
                            engineId: 4,
                            voiceId: 5
                        }
                    }
                }
                let data = {
                    kioskId: this.selectedKiosk.id,
                    sitepalId: this.selectedAvatar.sitepalId,
                    avatarId: this.selectedAvatar.avatarId,
                    avatarName: this.avatarName,
                    langCodes: langInfo
                }
                this.$store.commit("setSelectedAvatar", data)
                this.$store.commit('setSelectAvatarViewer', false)
            }
        }

    },
    mounted() {

    }

}
</script>