import * as fb from "../../firebase";
import moment from "moment";
import axios from "axios";
export default {
    state: {
        selectKioskAnalytics: "",
        selectedDuration: "Daily",
        dailyBarChart: [
            { category: '8 AM', value: 0 },
            { category: '9 AM', value: 0 },
            { category: '10 AM', value: 0 },
            { category: '11 AM', value: 0 },
            { category: '12 PM', value: 0 },
            { category: '1 PM', value: 0 },
            { category: '2 PM', value: 0 },
            { category: '3 PM', value: 0 },
            { category: '4 PM', value: 0 },
            { category: '5 PM', value: 0 }
        ],
        pieChart: [
            { label: 'Faqs', value: 0 },
            { label: 'Maps', value: 0 },
            { label: 'Forms', value: 0 },
            { label: 'Payments', value: 0 },
            { label: 'Hearings', value: 0 },
        ],
        languageBarChart: [
            { category: 'English', value: 0 },
            { category: 'Spanish', value: 0 }
        ],
        pieChartTotal: 0
    },
    getters: {
        selectKioskAnalytics: state => state.selectKioskAnalytics,
        dailyBarChart: state => state.dailyBarChart,
        pieChart: state => state.pieChart,
        getPieChartTotal: state => state.pieChartTotal,
        languageBarChart: state => state.languageBarChart,
        selectedDuration: state => state.selectedDuration
    },
    mutations: {
        setSelectKioskAnalytics(state, kioskid) {
            state.selectKioskAnalytics = kioskid
        },
        setSelectedDuration(state, duration) {
            state.selectedDuration = duration
        },
        setPieChartTotal(state, total) {
            state.pieChartTotal = total
        },
        resetPieChart(state) {
            state.pieChart = [
                { label: 'Faqs', value: 0 },
                { label: 'Maps', value: 0 },
                { label: 'Forms', value: 0 },
                { label: 'Payments', value: 0 },
                { label: 'Hearings', value: 0 },
            ]
        },
        resetLanguageBarChart(state) {
            state.languageBarChart = [
                { category: 'English', value: 0 },
                { category: 'Spanish', value: 0 }
            ]
        },
        resetDailyBarChart(state) {
            state.dailyBarChart = [
                { category: '8 AM', value: 0 },
                { category: '9 AM', value: 0 },
                { category: '10 AM', value: 0 },
                { category: '11 AM', value: 0 },
                { category: '12 PM', value: 0 },
                { category: '1 PM', value: 0 },
                { category: '2 PM', value: 0 },
                { category: '3 PM', value: 0 },
                { category: '4 PM', value: 0 },
                { category: '5 PM', value: 0 }
            ]
        }
    },
    actions: {
        getKioskAnalytics({ commit, getters, dispatch }, duration) {
            return new Promise((resolve, reject) => {
                let analyticsObj = {};
                let usersessionList = []
                const today = new Date();
                let startDate, endDate;
                if (duration.type === 'Daily') {

                    today.setHours(0, 0, 0, 0)
                    startDate = today.toISOString()
                    const today1 = new Date();
                    const tomorrow = new Date(today1);
                    tomorrow.setDate(today1.getDate() + 1);
                    endDate = tomorrow.toISOString()

                } else if (duration.type === 'Weekly') {

                    const weekToday = new Date()
                    const currentDayOfWeek = weekToday.getDay();

                    const startW = new Date(weekToday);
                    startW.setDate(weekToday.getDate() - currentDayOfWeek);
                    startDate = startW.toISOString()
                    const endW = new Date(weekToday);
                    endW.setDate(weekToday.getDate() + (6 - currentDayOfWeek));
                    endDate = endW.toISOString()

                } else if (duration.type === 'Annually') {
                    const annualToday = new Date()
                    const oneYearAgo = new Date(annualToday);
                    oneYearAgo.setFullYear(annualToday.getFullYear() - 1);
                    startDate = oneYearAgo.toISOString()
                    endDate = annualToday.toISOString()
                } else {
                    console.log('Custom Duration')
                }
                console.log('Start Date: ', startDate)
                console.log('End Date: ', endDate)
                console.log('Kiosk Id: ', getters.selectKioskAnalytics)
                let config1 = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `https://cms.akron.arsconnect.com/user-sessions?kioskId=${getters.selectKioskAnalytics}&sessionStartTime_gte=${startDate}&sessionStartTime_lte=${endDate}&_limit=-1`,
                    headers: {}
                };

                let config2 = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `https://cms.akron.arsconnect.com/qnas?kioskID=${getters.selectKioskAnalytics}&timeStamp_gte=${startDate}&timeStamp_lte=${endDate}&_limit=-1`,
                    headers: {}
                };
                axios.request(config1)
                    .then((response) => {
                        console.log('Response from Strapi: ', response.data)
                        usersessionList = response.data
                        console.log('User Session List 1: ', usersessionList)
                        axios.request(config2)
                            .then((response) => {
                                analyticsObj = {
                                    qnaAnalytics: response.data,
                                    userSessionAnalytics: usersessionList,
                                    totalQuestionsAsked: response.data.length,
                                    totalUserSessions: usersessionList.length,
                                    overallPerformance: 0
                                }
                                commit('setKioskAnalytics', analyticsObj);
                                commit('resetDailyBarChart')
                                commit('resetPieChart')
                                commit('resetLanguageBarChart')
                                console.log('User Session List: ', usersessionList)
                                if (usersessionList.length !== 0) {
                                    if (duration.type === 'Daily') {

                                        dispatch('createDailyBarChart', usersessionList)
                                    }
                                    dispatch('createPieChart', usersessionList)
                                    dispatch('createLanguageBarChart', usersessionList)
                                } else {
                                    //
                                }
                                resolve('Kiosk analytical data fetched');
                            })
                            .catch((error) => {
                                reject(error.message);
                            });
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
                resolve('Kiosk analytical data fetched');
            })
        },
        createDailyBarChart({ getters, commit }, usersessionList) {
            usersessionList.forEach(session => {
                console.log('User Sessions at Daily Bar chart:', session)
                let day = moment(session.sessionEndTime).format('H')
                console.log('Time of the session: ', day)
                switch (day) {
                    case '8':
                        var eight = getters.dailyBarChart.find(item => item.category === '8 AM')
                        eight.value = eight.value + 1
                        break;
                    case '9':
                        var nine = getters.dailyBarChart.find(item => item.category === '9 AM')
                        nine.value = nine.value + 1
                        break;
                    case '10':
                        var ten = getters.dailyBarChart.find(item => item.category === '10 AM')
                        ten.value = ten.value + 1
                        break;
                    case '11':
                        var eleven = getters.dailyBarChart.find(item => item.category === '11 AM')
                        eleven.value = eleven.value + 1
                        break;
                    case '12':
                        var twelve = getters.dailyBarChart.find(item => item.category === '12 PM')
                        twelve.value = twelve.value + 1
                        break;
                    case '13':
                        var thirteen = getters.dailyBarChart.find(item => item.category === '1 PM')
                        thirteen.value = thirteen.value + 1
                        break;
                    case '14':
                        var fourteen = getters.dailyBarChart.find(item => item.category === '2 PM')
                        fourteen.value = fourteen.value + 1
                        break;
                    case '15':
                        var fifteen = getters.dailyBarChart.find(item => item.category === '3 PM')
                        fifteen.value = fifteen.value + 1
                        break;
                    case '16':
                        var sixteen = getters.dailyBarChart.find(item => item.category === '4 PM')
                        sixteen.value = sixteen.value + 1
                        break;
                    case '17':
                        var seventeen = getters.dailyBarChart.find(item => item.category === '5 PM')
                        seventeen.value = seventeen.value + 1
                        break;
                }
            })
        },
        createPieChart({ getters, commit }, usersessionList) {
            commit('resetPieChart')
            usersessionList.forEach(session => {
                console.log('Sessions: ', session.userSession)
                const data = JSON.parse(session.userSession)

                var faq = getters.pieChart.find(item => item.label === 'Faqs')
                faq.value = faq.value + data.filter(item => item.response === "faqs").length

                var form = getters.pieChart.find(item => item.label === 'Forms')
                form.value = form.value + data.filter(item => item.response === "forms").length

                var map = getters.pieChart.find(item => item.label === 'Maps')
                map.value = map.value + data.filter(item => item.response === "maps").length

                var pay = getters.pieChart.find(item => item.label === 'Payments')
                pay.value = pay.value + data.filter(item => item.response === "payments").length

                var hearing = getters.pieChart.find(item => item.label === 'Hearings')
                hearing.value = hearing.value + data.filter(item => item.response === "todayshearing").length
                commit("setPieChartTotal", faq.value + form.value + map.value + pay.value + hearing.value)
            })
        },
        createLanguageBarChart({ getters }, usersessionList) {
            usersessionList.forEach(session => {
                const data = JSON.parse(session.userSession)
                var english = getters.languageBarChart.find(item => item.category === 'English')
                english.value = usersessionList.length

                var spanish = getters.languageBarChart.find(item => item.category === 'Spanish')
                spanish.value = spanish.value + data.filter(item => item.response === "Language changed to Spanish").length
            })
        }
    }
}